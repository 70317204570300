import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img from 'gatsby-image';
import './Portfoliosingle.scss';

const Aside = ({ img, copy }) => (
  <div className="portfolio-single__embed">
    <Img
      className="rounded img-fluid"
      fluid={img}
    />
    <div className="portfolio-single__aside-copy">
      {documentToReactComponents(copy)}
    </div>
  </div>
);

const Parser = ({ element, assets }) => {
  switch(element.nodeType) {
    case 'embedded-asset-block':
      return (
        <div className="portfolio-single__embed">
          <Img
            className="my-3 rounded mx-auto d-block"
            fluid={assets[element.data.target.sys.contentful_id]}
          />
        </div>
      );
    case 'embedded-entry-block':
      return (
        <Aside
          img={assets[element.data.target.fields.image['en-US'].sys.contentful_id]}
          copy={element.data.target.fields.copy['en-US']}
        />
      );
    default:
      return documentToReactComponents(element);
  }
}

const Portfoliosingle = ({ pageContext: { content, assets } }) => (
  <section className="portfolio-single portfolio_details_area sec_pad">
    <div className="portfolio-single__content" >
      {content.map((element, i) => (
        <Parser key={i} element={element} assets={assets}/>
      ))}
    </div>
  </section>
);

export default Portfoliosingle;
